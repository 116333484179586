import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import { useForm } from 'react-hook-form';
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import ModalForm from "../components/ModalForm";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import GlobalContext from "../context/GlobalContext";
import { validation } from "../utils/validation";
import notify from "../utils/notify";
import { addSubCategory } from "../api";

const DashboardAdminSettings = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [setting, setSetting] = useState(null);
  const { auth } = useContext(AuthContext);
  const gContext = useContext(GlobalContext);
  const {
    postJobFee,
    commissionRate,
    talentRate,
    featureProfileFee,
    getRevenueModel,
    setRevenueModel,
    categories,
    setCategories,
    getCategories,
  } = useContext(DataContext);
  const { register, errors, handleSubmit, reset, control } = useForm();

  const fetchRevenueSettings = async () => {
    await getRevenueModel();
    // setLoading(false);
  };

  const fetchCategorySettings = async () => {
    await getCategories();
    // setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    } else {
      fetchRevenueSettings();
      fetchCategorySettings();
      setLoading(false);
    }
  }, []);

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      // console.log(setting, data);
      if (["postJobFee", "commissionRate", "talentRate", "featureProfileFee"].includes(setting)) { // Update revenue settings
        const response = await setRevenueModel(data);
        if (response) {
          setSubmitting(false);
          await gContext.toggleFormModal();
          notify("Settings updated successfully", "success");
          setLoading(true);
          await fetchRevenueSettings();
          setLoading(false);
        }
      } else if (setting?.categoryname) { // Set new subcategory
        const payload = {
          ...data,
          value: data.label.toLowerCase(),
        };
        // console.log(payload);
        const response = await addSubCategory(setting.categoryname, payload);
        if (response) {
          setSubmitting(false);
          await gContext.toggleFormModal();
          notify("Settings updated successfully", "success");
          setLoading(true);
          await fetchCategorySettings();
          setLoading(false);
        }
      } else { // Set new category
        const payload = {
          ...data,
          value: data.label.toLowerCase(),
          subcategories: []
        };
        // console.log(payload);
        const response = await setCategories(payload);
        if (response) {
          setSubmitting(false);
          await gContext.toggleFormModal();
          notify("Settings updated successfully", "success");
          setLoading(true);
          await fetchCategorySettings();
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  }

  const handleSetSetting = async (e, data) => {
    e.preventDefault();
    setSetting(data);
    gContext.toggleFormModal();
  };

  const handleCategories = async (e, data) => {
    e.preventDefault();
    setSetting({
      category: true,
      label: data,
    });
    // console.log(data);
    gContext.toggleFormModal();
  };

  const handleSubCategories = async (e, categoryname, data) => {
    e.preventDefault();
    setSetting({
      category: true,
      categoryname,
      label: data,
    });
    // console.log(data);
    gContext.toggleFormModal();
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Settings</h3>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                      <div className="">
                        <Nav
                          className="nav border-mercury pl-4"
                          role="tablist"
                        >
                          <li className="tab-menu-items nav-item pr-12">
                            <Nav.Link
                              eventKey="one"
                              className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0"
                            >
                              Revenue
                            </Nav.Link>
                          </li>
                          <li className="tab-menu-items nav-item pr-12">
                            <Nav.Link
                              eventKey="two"
                              className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0"
                            >
                              Categories
                            </Nav.Link>
                          </li>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="one">
                            <div className="table-responsive pb-14">
                              <table className="table table-striped mt-3">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className="pl-4 border-0 font-size-4 font-weight-normal"
                                    >
                                      Item
                                    </th>
                                    <th
                                      scope="col"
                                      className="pl-4 border-0 font-size-4 font-weight-normal"
                                    >
                                      Value
                                    </th>
                                    <th
                                      scope="col"
                                      className="pl-4 border-0 font-size-4 font-weight-normal"
                                    ></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="border border-color-2">
                                    <th
                                      scope="row"
                                      className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                    >
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        Post Job Fee
                                      </h3>
                                    </th>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      {loading ? (
                                        <Skeleton width={200} height={34} />
                                      ) : (
                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                          {postJobFee}
                                          {" "}
                                          NEAR
                                        </h3>
                                      )}
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-80">
                                      <button
                                        onClick={(e) => handleSetSetting(e, "postJobFee")}
                                        className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                      >
                                        Update
                                      </button>
                                    </td>
                                  </tr>

                                  <tr className="border border-color-2">
                                    <th
                                      scope="row"
                                      className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                    >
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        Devshop Admin Commission
                                      </h3>
                                    </th>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      {loading ? (
                                        <Skeleton width={200} height={34} />
                                      ) : (
                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                          {commissionRate}
                                          {" "}
                                          %
                                        </h3>
                                      )}
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-80">
                                      <button
                                        onClick={(e) => handleSetSetting(e, "commissionRate")}
                                        className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                      >
                                        Update
                                      </button>
                                    </td>
                                  </tr>

                                  <tr className="border border-color-2">
                                    <th
                                      scope="row"
                                      className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                    >
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        Talent Rate
                                      </h3>
                                    </th>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      {loading ? (
                                        <Skeleton width={200} height={34} />
                                      ) : (
                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                          {talentRate}
                                          {" "}
                                          %
                                        </h3>
                                      )}
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-80">
                                      <button
                                        onClick={(e) => handleSetSetting(e, "talentRate")}
                                        className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                      >
                                        Update
                                      </button>
                                    </td>
                                  </tr>

                                  <tr className="border border-color-2">
                                    <th
                                      scope="row"
                                      className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                    >
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        Feature Profile Fee
                                      </h3>
                                    </th>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      {loading ? (
                                        <Skeleton width={200} height={34} />
                                      ) : (
                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                          {featureProfileFee}
                                          {" "}
                                          NEAR
                                        </h3>
                                      )}
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-80">
                                      <button
                                        onClick={(e) => handleSetSetting(e, "featureProfileFee")}
                                        className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                      >
                                        Update
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="two">
                            <div className="mt-7">
                              <div>
                                <button
                                  type="button"
                                  className="border-0 bg-transparent text-uppercase font-size-3 font-weight-semibold text-green focus-reset"
                                  onClick={(e) => handleCategories(e, "")}
                                >
                                  <i className="fa fa-plus"></i>
                                  <span className="ml-2">New Category</span>
                                </button>
                              </div>
                              <hr />

                              <ul className="mt-11">
                                {categories?.length > 0 && categories.map((category, index) => (
                                  <li key={index}>
                                    <div className="mb-9">
                                      <div className="d-flex justify-content-between bg-light px-1 py-2 p-sm-4">
                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-2">
                                          {category?.label}
                                        </h3>
                                        <div className="d-flex align-items-center">
                                          {/* <button
                                            type="button"
                                            className="border-0 bg-transparent text-uppercase font-size-3 font-weight-semibold text-green focus-reset mr-4"
                                            onClick={(e) => handleCategories(e, category.label)}
                                          >
                                            <i className="fa fa-edit"></i>
                                            <span className="ml-2">Edit</span>
                                          </button> */}
                                          <button
                                            type="button"
                                            className="border-0 bg-transparent text-uppercase font-size-3 font-weight-semibold text-green focus-reset"
                                            onClick={(e) => handleSubCategories(e, category.label, "")}
                                          >
                                            <i className="fa fa-plus"></i>
                                            <span className="ml-2">Add Subcategory</span>
                                          </button>
                                        </div>
                                      </div>
                                      <ul>
                                        {category?.subcategories.length > 0 && category?.subcategories.map((subcategory, i) => (
                                          <li key={i}>
                                            <div className="my-1">
                                              <div className="d-flex justify-content-between">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-2">
                                                  {subcategory?.label}
                                                </h3>
                                                <div>
                                                  {/* <button
                                                    type="button"
                                                    className="border-0 bg-transparent text-uppercase font-size-3 font-weight-semibold text-green focus-reset"
                                                    onClick={(e) => handleSubCategories(e, category.label, subcategory.label)}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                    <span className="ml-2">Edit</span>
                                                  </button> */}
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalForm>
            <div className="py-10 px-sm-10 h-100 d-flex flex-column">
              <div className="pb-9">
                <h3 className="font-size-6 mb-0 text-center">
                  {setting === "postJobFee" ? "Update Post Job Fee"
                    : setting === "commissionRate" ? "Update Devshop Admin Commission"
                      : setting === "talentRate" ? "Update Talent Rate"
                        : setting === "featureProfileFee" ? "Update Feature Profile Fee"
                          : setting?.categoryname ? `Add ${setting?.categoryname} Subcategory`
                            : "Add Category"}
                </h3>
              </div>

              <div className="pb-9 px-11">
                <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                  {setting?.category ? (
                    <div className="form-group mb-8">
                      <label
                        htmlFor="label"
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        Label
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="label"
                        name="label"
                        defaultValue={setting?.label}
                        ref={register({ required: true })}
                      />
                      <span className="text-danger small">{errors?.label?.message || ''}
                      </span>
                    </div>
                  ) : (
                    <div className="form-group mb-8">
                      <label
                        htmlFor={setting === "postJobFee" ? "Post Job Fee"
                          : setting === "commissionRate" ? "Devshop Admin Commission"
                            : setting === "talentRate" ? "Talent Rate"
                              : setting === "featureProfileFee" ? "Feature Profile Fee"
                                : null}
                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                      >
                        {setting === "postJobFee" ? "Post Job Fee*"
                          : setting === "commissionRate" ? "Devshop Admin Commission*"
                            : setting === "talentRate" ? "Talent Rate*"
                              : setting === "featureProfileFee" ? "Feature Profile Fee*"
                                : null}
                      </label>
                      <input
                        type="number"
                        step={setting === "postJobFee" ? 0.01
                          : setting === "commissionRate" ? 1
                            : setting === "talentRate" ? 1
                              : setting === "featureProfileFee" ? 0.01
                                : 1}
                        className="form-control"
                        id={setting === "postJobFee" ? "postJobFee"
                          : setting === "commissionRate" ? "commissionRate"
                            : setting === "talentRate" ? "talentRate"
                              : setting === "featureProfileFee" ? "featureProfileFee"
                                : null}
                        name={setting === "postJobFee" ? "postJobFee"
                          : setting === "commissionRate" ? "commissionRate"
                            : setting === "talentRate" ? "talentRate"
                              : setting === "featureProfileFee" ? "featureProfileFee"
                                : null}
                        defaultValue={setting === "postJobFee" ? postJobFee
                          : setting === "commissionRate" ? commissionRate
                            : setting === "talentRate" ? talentRate
                              : setting === "featureProfileFee" ? featureProfileFee
                                : null}
                        ref={register({
                          required: setting === "postJobFee" ? "Post Job Fee is required"
                            : setting === "commissionRate" ? "Admin Commission Rate is required"
                              : setting === "talentRate" ? "Talent Rate is required"
                                : setting === "featureProfileFee" ? "Feature Profile Fee is required"
                                  : true,
                          min: setting === "postJobFee" ? 0.01
                            : setting === "commissionRate" ? 1
                              : setting === "talentRate" ? 1
                                : setting === "featureProfileFee" ? 0.01
                                  : 0,
                          max: setting === "commissionRate" ? 100 - talentRate
                            : setting === "talentRate" ? 100 - commissionRate
                              : 100,
                        })}
                      />
                      <span className="text-danger small">{setting === "postJobFee" ? errors?.postJobFee?.message || ''
                        : setting === "commissionRate" ? errors?.commissionRate?.message || ''
                          : setting === "talentRate" ? errors?.talentRate?.message || ''
                            : setting === "featureProfileFee" ? errors?.featureProfileFee?.message || ''
                              : null}
                      </span>
                    </div>
                  )}

                  <div className="form-group mb-8">
                    <button
                      type="submit"
                      className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase focus-reset"
                      disabled={submitting ? true : false}
                    >
                      {submitting ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </ModalForm>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdminSettings;
